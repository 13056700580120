import * as React from 'react';
import { useCallback } from 'react';
import { Card } from '@mui/material';
import Footer from '@assets/images/survey/Footer.png';
import LockIcon from '@mui/icons-material/Lock';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useContent from '@view/content-management-system/hooks/useContent';
import { Content } from '@data/contents/Contents.model';
import { ReactComponent as Subtract } from '@assets/icons/subtract.svg';

const SurveyView: React.FC<{
  title?: string;
  surveyLink?: string;
  summary?: string;
  category?: string;
  content: Content;
}> = ({ title, summary, surveyLink, category, content }) => {
  // const navigate = useNavigate();
  const [showSurvey, setShowSurvey] = React.useState(false);
  const { publishedAt, views, cover, contentType } = useContent(content);

  const TextWithLineBreaks = useCallback(({ text }) => {
    if (!text) return null;
    return (
      <>
        {text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  }, []);

  if (showSurvey) {
    return (
      <iframe
        src={surveyLink}
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
      </iframe>
    );
  }

  return (
    <Card
      className={`h-full overflow-auto    max-w-[760px] bg-lighter-background `}
    >
      <div className="relative flex flex-col h-full">
        <div className=" w-full h-12 bg-white flex justify-center items-center">
          <p className=" w-full h5 text-on-primary text-center">{category}</p>
        </div>
        <div className="flex flex-col pb-3 bg-white h-fit">
          <img
            src={cover}
            alt={title}
            className="object-cover w-full h-[25%] max-h-[200px]"
          />
          <div
            className="flex items-center w-full h-12 bg-white text-[#4D565F]"
            style={{
              marginTop: '12px',
              fontFamily: 'Roboto',
              fontSize: '0.875 rem',
              fontWeight: 400,
            }}
          >
            <Subtract className="mr-2 ml-2" />
            <p className="caption mr-1">Quiz 5 min</p>
            <CircleIcon
              style={{
                width: '4px',
                height: '4px',
              }}
            />
            <VisibilityIcon
              style={{
                width: '12px',
                marginLeft: '4px',
                marginRight: '4px',
              }}
            />
            <p className="caption">2.2k views</p>
          </div>

          <p className="w-full pl-3 mt-2 mb-2 h1 text-on-primary text-start">
            {title}
          </p>
          <p className="flex flex-row justify-start w-full pl-3 pr-3 mb-3 overline text-on-primary text-start text-secondary">
            <LockIcon className="w-4 h-4 mr-1" /> All your answers are private.
          </p>
          <p className="w-full pl-3 pr-3 mb-3 body1 text-on-primary text-start">
            {summary}
            {TextWithLineBreaks(summary ?? '')}
          </p>
          <div className="flex justify-center w-full pr-3 ">
            <button
              className="h-10 text-white button bg-[#103158] rounded-[100px] w-[260px]"
              style={{
                backgroundColor: '#103158',
                width: '260px',
              }}
              onClick={() => setShowSurvey(true)}
            >
              Begin
            </button>
          </div>
        </div>
        <img
          src={Footer}
          className="absolute bottom-0 object-contain h-fit"
          alt=""
        />
      </div>
    </Card>
  );
};

export default SurveyView;

//
// <div className="relative pl-6 pr-6">

// </div>
