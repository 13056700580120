// @flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import LoadingPage from '@shared/components/loading-page';
import useAppDispatch from '@data/useAppDispatch';
import { getContent } from '@data/contents/Contents.thunk';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import DropDownMenu from '@shared/components/dropDown-menu/DropDownMenu';
import { ContentTypes } from '@data/contents/Contents.model';
import useContentActions from '@view/content-management-system/hooks/useContentActions';
import SeriesView from '@view/discover-page/components/SeriesView';
import ArticleView from '@view/discover-page/components/ArticleView';
import useContent from '@view/content-management-system/hooks/useContent';
import SurveyView from '@view/discover-page/components/SurveyView';
import { getContentTypeView } from '../../logic/CMS/content.logic';

const ContentPreview: React.FC<unknown> = () => {
  const { contentId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [contentIsFetched, setContentIsFetched] = React.useState(false);
  const content = useSelector((state: any) =>
    ContentsSelectors.contents.selectById(state.contents, contentId || -1),
  );
  console.log(content);
  const previewRef = React.useRef(null);
  const [selectedStep, setSelectedStep] = React.useState(0);
  const { actions, mainAction, ConfirmationModal } = useContentActions(
    content,
    previewRef,
    selectedStep,
  );
  const [view, setView] = React.useState<'android' | 'ios'>('android');
  React.useEffect(() => {
    if (!contentIsFetched && contentId) {
      dispatch(
        getContent({
          id: contentId,
        }),
      ).then(() => {
        setIsLoading(false);
        setContentIsFetched(true);
      });
    }
  }, [contentId, contentIsFetched, dispatch, setIsLoading]);

  const getWidth = () => {
    return view === 'android' ? 343 : 360;
  };

  const getHeight = () => {
    return view === 'android' ? 640 : 667;
  };

  const { seriesDetails, rawHtmlContent } = useContent(content);

  return (
    <WithSideBar>
      <ConfirmationModal />
      <div className="w-full h-full">
        {isLoading ? (
          <LoadingPage />
        ) : (
          <div className="flex justify-center w-full h-full">
            <div className="flex justify-center w-full h-full pt-8 pb-8 overflow-hidden ">
              <div className="mr-4 w-[94px]">
                <Button
                  onClick={() => setView('android')}
                  variant="contained"
                  className={`w-full ${
                    view !== 'android' ? '!bg-white' : '!bg-accent/70'
                  } text-on-primary rounded-b-none`}
                >
                  Android
                </Button>
                <Button
                  onClick={() => setView('ios')}
                  variant="contained"
                  className={`w-full ${
                    view !== 'ios' ? '!bg-white' : '!bg-accent/70'
                  } text-on-primary rounded-t-none`}
                >
                  iOS
                </Button>
              </div>
              <div
                style={{ height: '100%', width: '360px', overflow: 'auto' }}
                ref={previewRef}
              >
                {content && content.type === ContentTypes.ARTICLE && (
                  <ArticleView
                    id={content.id}
                    cover={content.cover || ''}
                    title={content.title}
                    htmlContent={rawHtmlContent || ''}
                    contentType={getContentTypeView(content?.type)}
                    readTime={content.readTime || ''}
                    pdfContent={content.pdfContent}
                    articleType={content.articleType || ''}
                    isPreview
                    seriesDetails={seriesDetails}
                  />
                )}
                {content && content.type === ContentTypes.SERIES && (
                  <SeriesView
                    id={content.id}
                    cover={content.cover}
                    title={content.title}
                    numOfSteps={content.steps?.length}
                    numOfCompletedSteps={content.steps?.length}
                    steps={content.steps || []}
                    isStarted
                    isPreview
                    description={content.description}
                    contentType={getContentTypeView(content?.type)}
                  />
                )}
                {content && (
                  <SurveyView
                    content={content}
                    title={content.title}
                    summary={content.description}
                    surveyLink={content.surveyLink}
                    category={content.category}
                  />
                )}
              </div>
              <div className="flex">
                {actions.length > 0 ? (
                  <DropDownMenu
                    onClick={(action: string) => {
                      actions.find((a) => a.label === action)?.onClick();
                    }}
                    options={actions}
                  />
                ) : null}
                <Button
                  className="ml-4 text-white w-[128px]"
                  variant="contained"
                  color="primary"
                  onClick={mainAction.onClick}
                >
                  {mainAction.label}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </WithSideBar>
  );
};

export default ContentPreview;
